import * as React from 'react';

import { Container } from 'react-bootstrap';
import { Layout } from '../components';

const NotFoundPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <Container>
        <div style={{ fontSize: 70, marginTop: 50, height: '78vh' }}>SORRY, THAT PAGE CAN'T BE FOUND. (404 ERROR)</div>
      </Container>
      <Layout.Footer />
    </>
  );
};

export default NotFoundPage;

export const Head = () => <Layout.PageHead subtitle="404" />;
